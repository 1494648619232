import React from 'react'
import get from 'lodash/get'
import { NumberField } from 'react-admin'
import { LOCALE_DEFAULT } from '../config/locale'

const UMPriceField = ({ record, format, ...props }) => {
  let formattedRecord = record
  if (record && format) {
    formattedRecord = {
      ...record,
      [props.source]: format(get(record, props.source)),
    }
  }
  return <NumberField {...props} record={formattedRecord} />
}

UMPriceField.defaultProps = {
  addLabel: true,
  textAlign: 'left',
  locales: LOCALE_DEFAULT,
  options: { style: 'currency', currency: 'EUR' },
}

export default UMPriceField
